/* custom variables */
:root {
  --light-gray: #eaedf1;
  --medium-gray: #929396;
  --dark-gray: #4c4d4e;
  --input-field-background: #0000000a;
}

/* scrollbar styling */
::-webkit-scrollbar {
  /* width: 5px; */
  width: 0;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--medium-gray);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-gray);
}

/* tag styling */
body {
  background-color: var(--light-gray);
}

/* font styling */
.icon-size-large {
  font-size: 0.9rem;
}
.icon-size-medium {
  font-size: 0.7rem;
}
.timestamp-size {
  font-size: 0.75rem;
}

/* MUI classes */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: 0 !important;
}

/* other classes */
.pointer {
  cursor: pointer;
}

.hover-bg:hover {
  background-color: var(--light-gray) !important;
}

.hover-bg:hover span {
  visibility: visible !important;
}

.hover-visible:hover,
.hover-visible:hover span {
  visibility: visible !important;
}

/* custom component styling */
.custom-comment-input,
.custom-input {
  width: -webkit-fill-available;
  border: none;
  background: var(--input-field-background);
  border-radius: 50px;
  padding: 0 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.custom-search-bar {
  border: 0;
  width: -webkit-fill-available;
  display: flex;
}

.custom-search-bar input,
.custom-input input {
  width: 84%;
  display: inline;
  border: 0;
  background-color: transparent;
}

.custom-search-icon {
  margin: 0 10px 0 5px;
}

.custom-hr {
  height: 1px;
  background-color: var(--light-gray);
  margin: 1rem 0;
}

.custom-hr-sm {
  height: 1px;
  background-color: var(--light-gray);
  margin: 0.5rem 0;
}
